import { DeliveryUnit, DeliveryUnitPositions } from '@/features/delivery-unit';
import { reactive } from 'vue';

function getUnitPositionCounts(units: DeliveryUnit[]): Record<string, number> {
  return units.reduce((acc, currentUnit) => {
    acc[currentUnit.position] = (acc[currentUnit.position] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);
}

export function initializeDeliveryUnitPositions(
  units: DeliveryUnit[],
): DeliveryUnitPositions {
  const unitPositionCounts = getUnitPositionCounts(units);

  return units.reduce((acc, currentUnit) => {
    acc[currentUnit.position] = {
      totalCount: unitPositionCounts[currentUnit.position],
      scannedBarcode: acc[currentUnit.position]?.scannedBarcode || reactive([]),
    };
    return acc;
  }, {} as DeliveryUnitPositions);
}
